import React, { useEffect } from "react";
import classNames from "classnames";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { YMInitializer } from "react-yandex-metrika";
import { uuid } from "uuidv4";

import store from "./store";
import { migrate } from "./migrations";

import { getDeviceInfo, isIphoneX, isStandalone } from "../shared/DeviceUtils";
import AppContent from "./AppContent";

import "../sass/app.scss";
import "swiper/js/swiper.min.js";
import Api from "../shared/networking/Api";

function App() {
  // TODO: standalone app improvements
  toast.configure();
  migrate();
  getDeviceInfo(function () {});

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      Api().ordersService.userSubscriptionPackages({
        success: (response) => {
          try {
            if (response.orders && response.orders.length > 0) {
              response.orders.forEach((item) => {
                if (
                  localStorage.getItem("GA4_SENT_ACTIVE_SUB_" + item.id) !==
                    "true" &&
                  window.dataLayer
                ) {
                  window.dataLayer.push({
                    event: "purchase",
                    order_id: item.id,
                    order_total: item.amount,
                    items: item.subscriptionPackageOrder.map(
                      (o) => o.subscriptionPackageId,
                    ),
                    customer_phone: localStorage.getItem("PROFILE_USERNAME"),
                  });
                  localStorage.setItem("GA4_SENT_ACTIVE_SUB_" + item.id);
                }
              });
            }
          } catch (e) {}
        },
        error: () => {},
      });
    } else {
    }
  };

  useEffect(() => {
    handleVisibilityChange();
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const currentUserId = localStorage.getItem("userId");

    if (!currentUserId) {
      localStorage.setItem("userId", uuid());
    }
  }, []);

  useEffect(() => {
    // Function to get UTM parameters from URL
    const getUTMFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get("utm_source");
      const utmMedium = urlParams.get("utm_medium");
      const utmCampaign = urlParams.get("utm_campaign");

      const referrer = document.referrer || null;

      if (utmSource)
        // Store UTM parameters in local storage
        localStorage.setItem("utm_source", utmSource || "");
      if (utmMedium) localStorage.setItem("utm_medium", utmMedium || "");
      if (utmCampaign) localStorage.setItem("utm_campaign", utmCampaign || "");

      if (referrer) localStorage.setItem("referrer", referrer);
    };

    // Call the function on component mount
    getUTMFromURL();
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div
      className={classNames("App", {
        "iphone-x": isIphoneX() && isStandalone(),
      })}
    >
      <Provider store={store}>
        <YMInitializer
          accounts={[55297420]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
        />
        <AppContent />
        <ToastContainer
          rtl
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
        />
      </Provider>
    </div>
  );
}

export default App;
